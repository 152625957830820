{#if (config.canEdit || showTableViewLinkForReadOnlyMode)}
    {#if !isDataLoading}
        <div class="its-plans__switch">
            <Link
                kind="standalone"
                href={destinationUrl}
                rel="noopener"
                iconClass="its-plans__switch-table-iconLink"
                iconPlacement="start"
                target="_self"
                text={i18n.tableView}
                on:click
            />
        </div>
    {/if}
{/if}

<script>
    import Link from "@itslearning/prometheus/assets/Navigation/Link/v1/Link.svelte";
    import { addBackDestinationParam } from "../../helpers/backLinkHelper";
    import { planType } from "../../constants";

    export let config;
    export let i18n;
    export let isDataLoading;
    export let backExtraParams;

    const showTableViewLinkForReadOnlyMode = config.showReadOnlyTableView && config.planType !== planType.onePlanAtATime && !config.publicAccessKey;

    $: destinationUrl = addBackDestinationParam(
        `/plans/courses/${config.courseId}/tableView`,
        backExtraParams
    );
</script>
