{#if iconType != iconless}
<IconButton
    {...$$restProps}
    {type}
    {htmlType}
    iconPlacement="start"
    iconClass={`its-plans__${iconType}-icon`}
    {text}
    on:click
/>
{:else}
<Button
    {...$$restProps}
    {type}
    {htmlType}
    {text}
    on:click
/>
{/if}

<script>
    import Button from "@itslearning/prometheus/assets/inputs/Button/v1/Button.svelte";
    import IconButton from "@itslearning/prometheus/assets/inputs/IconButton/v1/IconButton.svelte";

    const type = 'plain';
    const htmlType = 'button';
    const iconless = 'none';

    /** @type {'none'|'add'|'change'|'delete'} */
    export let iconType = iconless;
    
    /** @type {string} */
    export let text;
</script>
